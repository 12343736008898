<template lang="pug">
el-dialog(
  :title="title"
  :visible="visible"
  :close-on-click-modal="false"
  custom-class="page__dialog product-skus-selector"
  @close="$emit('close')")

  el-form
    .filters
      .filter
        el-form-item.boxed(label="Search")
          el-input(
            v-model="query['q[any_text]']"
            placeholder="Type in SKU name or brand name here to search"
            @input="debouncedSearch")

    .records
      loading(v-if="loading")

      .skus.with-scroller(v-else)
        .sku(
          v-for="i, k in records"
          :class="{'sku--selected': !!ids[i.id], 'sku--excluded': excludes.includes(i.id)}"
          :key="k"
          @click="toggleSku(i, k)")
          .sku__select(@click.stop)
            el-checkbox(v-model="ids[i.id]" :disabled="excludes.includes(i.id)")
          .sku__image
            .contain(v-image="i.primary_image_url")
          .sku__info
            .sku__brand.pf-ellipsis(v-text="i.brand_name")
            .sku__product.pf-ellipsis(v-text="i.product_name")
            .sku__name.pf-ellipsis(v-text="i.name")
            .sku__id(v-if="i.id"
              v-tooltip="'Item code is a unique identifier for SKUs that helps you search products and order with ease.'") # {{ i.id }}
    .footer-section.flex.aic.jcsb.mt-2.pt-2(:class="{'jcfe': !paginate, 'with-border-top': paginate}")
      paginator.edt-paginator(
        v-if="paginate"
        :data="paginate"
        manually-page-change
        @change="updatePage")
      .tr
        el-button(type="primary" @click="done" :disabled="!selected.length")
          template(v-if="selected.length")
            span Add&nbsp;
            strong {{ selected.length }}&nbsp;
            span selected {{ selected.length > 1 ? 'SKUs' : 'SKU' }}
          template(v-else) Select a SKU first
</template>

<script>
import { debounce } from 'lodash'
import ProductVariantStamp from '~/components/stamps/ProductVariantStamp'

export default {
  components: {
    ProductVariantStamp
  },

  props: {
    visible: {
      type: Boolean,
      default: true
    },

    title: {
      type: String,
      default: 'Select SKUs'
    },

    excludes: {
      type: Array,
      default: () => []
    },

    api: {
      type: Function,
      default: () => Promise.reject(new Error('No API provided'))
    }
  },

  data () {
    return {
      query: {},
      ids: {},
      loading: false,
      records: null,
      paginate: null,
      debouncedSearch: debounce(this.search, 400)
    }
  },

  computed: {
    selected () {
      if (!this.records) return []
      const ids = Object
        .keys(this.ids)
        .map(i => +i)
        .filter(i => !!this.ids[i])
      return this.records
        .filter(i => ids.includes(i.id))
    }
  },

  methods: {
    toggleSku (sku) {
      if (this.excludes.includes(sku.id)) return
      this.$set(this.ids, sku.id, !this.ids[sku.id])
    },

    updatePage (page) {
      this.ids = {}
      this.search(null, page)
    },

    search (term, page) {
      this.loading = true
      if (!page) this.paginate = null

      return this
        .api({ ...this.query, page: page || 1 })
        .then(res => {
          this.records = res.results
          this.paginate = this.$paginators.parse(res)
        })
        .finally(() => {
          this.loading = false
        })
    },

    done () {
      this.$emit('done', this.selected)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .product-skus-selector {
    max-width: 800px;
  }
}
.skus {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 1rem;
  overflow-y: scroll;
  max-height: 400px;
  .sku {
    display: flex;
    cursor: pointer;
    padding: .6rem;
    border-radius: $--radius-sm;
    > * {
      min-width: 0;
    }
    &--selected {
      background: rgba($--color-primary, .05);
    }
    &--excluded {
      background: darken(white, 3);
      cursor: not-allowed;
    }
    &__image {
      flex: 0 64px;
      margin-right: .75rem;
      font-size: 0;
      .contain {
        width: 100%;
        height: 0;
        padding-top: 100%;
        position: relative;
        background-color: $--color-placeholder;
        border-radius: $--radius-sm;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    &__select {
      margin-right: .75rem;
    }
    &__info {
      flex: 1;
      font-size: 13px;
    }
    &__brand {
      color: $--color-primary;
    }
    &__id {
      width: max-content;
      color: black;
      background: white;
      font-size: 11px;
      padding: 3px 5px;
      margin-top: 4px;
      border-radius: $--radius;
      border: $--border-light;
    }
  }
}
.footer-section {
  &.with-border-top {
    border-top: $--border-light;
  }
  @include media(xs-only) {
    flex-direction: column;
  }
  .edt-paginator {
    flex-direction: column;
    font-size: 13px;
  }
}
</style>
