<template lang="pug">
el-card(shadow="never" :body-style="{ padding: '0px' }")
  template(#header v-if="showHeader")
    .flex.jcsb
      .title Notifications
      nuxt-link.linked(:to="{ name: 'vendors-notifications' }") View all
  .flex.jcsb.aic.my-1.px-3(v-if="showFilter")
    .pills
      a(href="#" @click="type = 'all'" :class="{ 'active': type === 'all' }") All
      a(href="#" @click="type = 'unread'" :class="{ 'active': type === 'unread' }") Unread
    el-button(v-if="unreadNotifications" type="text" @click="handleMarkAllRead") Mark all as read

  infinite-fetcher.infinite-fetcher(
    v-loading="loading"
    :disable="loading"
    :fetch="fetch"
    :paginator="paginator"
    loading-text="Loading more...")
    .noti(v-for="n in notifications" :key="n.id" @click="handleMarkRead(n)" :class="{ 'has-action': showAction }")
      .noti-icon(v-if="n.data")
        i.icon.bx.bx-8(:class="[ICON_BY_TYPE[n.data.notification_type]]")
      .noti-desc
        v-nodes(:vnodes="genDesc(n)")
        .created
          span(v-tooltip="$options.filters.datetime(n.created_at)") {{ n.created_at | fromNow }}

      .noti-actions(v-if="showAction")
        el-dropdown(trigger="click" placement="bottom" @command="handleAction")
          el-button.trigger(round size="small")
            i.bx.bx-dots-horizontal-rounded.bx-5
          el-dropdown-menu.action-menu(slot="dropdown" size="small")
            el-dropdown-item.mb-2(:command="{action: ACTION_TYPES.DELETE, noti: n}")
              .flex.aic
                i.bx.bx-trash.bx-8
                .flex.fdc.ml-2
                  span.title Delete
                  span.desc Delete this notification
            el-dropdown-item(v-if="notiGroupIds.includes(n.notification_group_id)" :command="{action: ACTION_TYPES.TURN_OFF, noti: n}")
              .flex.aic
                i.bx.bx-bell-off.bx-8
                .flex.fdc.ml-2
                  .title Turn off
                  .desc Stop receiving notifications like this
      .noti-unread(v-show="!n.read")
        i.unread.bx.bxs-circle
    empty(
      v-if="!notifications.length"
      title=""
      description="")

</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'

import InfiniteFetcher from '~/components/InfiniteFetcher'
import { DEBOUNCE_DELAY } from '~/settings'

const ICON_BY_TYPE = {
  new_inbound_inventory: 'bx-box',
  new_sample: 'bxs-eyedropper',
  new_direct_order: 'bx-cart',
  new_statement: 'bx-bar-chart-alt-2'
}

const ACTION_TYPES = {
  DELETE: 'delete',
  TURN_OFF: 'turn-off'
}

export default {
  name: 'NotificationCard',

  components: {
    VNodes: {
      name: 'Vnodes',
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    },
    InfiniteFetcher
  },

  props: {
    defaultQuery: {
      type: Object,
      default: () => ({})
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    showAction: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      ACTION_TYPES,
      ICON_BY_TYPE,
      type: 'all',
      notifications: [],
      query: {},
      paginator: {},
      loading: false,
      debounced: () => {}
    }
  },

  computed: {
    ...mapGetters({
      notiGroupIds: 'authentication/notiGroupIds',
      unreadNotifications: 'notifications/unreadNotifications'
    })
  },

  watch: {
    type (val) {
      this.query = val === 'unread' ? { 'q[read]': false } : {}

      this.setLoading(true)
      this.reset()
      this.debounced()
      this.$store.commit('notifications/updateHasChangeNotification', false)
    }
  },

  created () {
    this.debounced = debounce(this.fetch, DEBOUNCE_DELAY)
  },

  methods: {
    setLoading (val) {
      this.loading = val
    },

    reset () {
      this.notifications = []
    },

    fetch (query = {}) {
      this.loading = true

      return this.$api.vendor.getNotifications({ ...query, ...this.defaultQuery, ...this.query })
        .then((res) => {
          this.paginator = this.$paginators.parse(res)
          this.notifications = [
            ...this.notifications,
            ...res.results
          ]
        })
        .finally(() => {
          this.loading = false
        })
    },

    genDesc ({ id, message, data }) {
      if (!data) return message || ''

      const instance = this
      const h = this.$createElement
      const m = message.split('here')
      const route = {
        new_inbound_inventory: { name: 'vendors-inventory-inbound-id', params: { id: data.inbound_inventory_id } },
        new_sample: { name: 'vendors-samples-id', params: { id: data.sample_request_id } },
        new_direct_order: { name: 'vendors-orders-id', params: { id: data.order_id } },
        new_statement: { name: 'vendors-payments-statements-id', params: { id: data.vendor_statement_id } }
      }

      return h('div', [
        m[0],
        h('nuxt-link',
          {
            class: 'linked',
            props: { to: route[data.notification_type] },
            nativeOn: {
              click (e) {
                instance.$amplitude.track('CLICK_VENDOR_DASHBOARD_NOTIFICATION', { ...data, noti_id: id })
              }
            }
          },
          ' here '),
        m[1]
      ])
    },

    handleMarkAllRead () {
      this.$cable.perform({
        channel: 'VendorChannel',
        action: 'mark_all_notifications_as_read'
      })

      this.notifications = this.notifications.map((e) => ({ ...e, read: true }))
      this.$store.commit('notifications/updateHasChangeNotification', true)
    },

    handleMarkRead (noti) {
      if (noti.read) return

      this.$cable.perform({
        channel: 'VendorChannel',
        action: 'mark_notification_as_read',
        data: { args: { notification_id: noti.id } }
      })

      this.$set(noti, 'read', true)
      this.$store.commit('notifications/updateHasChangeNotification', true)
      this.$amplitude.track('CLICK_VENDOR_DASHBOARD_NOTIFICATION_SEEN', { ...noti.data, noti_id: noti.id })
    },

    handleAction ({ action, noti }) {
      switch (action) {
        case ACTION_TYPES.DELETE:
          this.handleDelete(noti)
          break
        case ACTION_TYPES.TURN_OFF:
          this.handleTurnOff(noti)
          break
      }
    },

    handleDelete (noti) {
      this.$cable.perform({
        channel: 'VendorChannel',
        action: 'delete_notification',
        data: {
          args: { notification_id: noti.id }
        }
      })
      this.$workflow.notify('Deleted successfully.')
      this.notifications = this.notifications.filter(e => e.id !== noti.id)
      this.$store.commit('notifications/updateHasChangeNotification', true)
    },

    handleTurnOff (noti) {
      if (!this.notiGroupIds.includes(noti.notification_group_id)) return

      this.$store.dispatch('authentication/updatePersonalInformation', {
        notification_group_ids: this.notiGroupIds.filter(e => e !== noti.notification_group_id)
      }).then(() => {
        this.$workflow.notify('Updated successfully.')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.action-menu {
  ::v-deep {
    .el-dropdown-menu__item {
      line-height: 1.8em;
    }
  }
}

.title {
  font-weight: $--font-weight-bold;
  font-size: 1.2em;
}

.pills {
  margin-bottom: 0;

  >* {
    margin-bottom: 0;
  }
}

.infinite-fetcher {
  max-height: 500px;
  overflow-y: auto;
  padding: 0;
}
.noti {
  cursor: pointer;
  display: grid;
  grid-column-gap: 10px;
  align-items: center;
  grid-template-columns: 40px auto 20px;
  grid-template-areas: 'icon desc unread';
  padding: 10px 20px;
  border-radius: 4px;

  &.has-action {
    grid-template-columns: 40px auto 60px 20px;
    grid-template-areas: 'icon desc actions unread';
  }

  &:hover {
    background: lighten($--color-primary, 57);
  }

  &-icon {
    grid-area: icon;
    display: flex;
    justify-content: center;
    color: $--color-primary;
    background: #F4F6F8;
    padding: 5px;
    border-radius: 5px;
  }

  &-desc {
    grid-area: desc;
    display: flex;
    flex-direction: column;

    .created {
      font-size: 0.8em;
      color: #666e75;
    }
  }

  &-actions {
    grid-area: actions;
    display: flex;
    justify-content: center;
  }

  &-unread {
    grid-area: unread;
    display: flex;
    justify-content: center;
    color: $--color-danger;
  }
}
</style>
