<template lang="pug">
.categories-bar
  .scroller(v-horizontal-scroll)
    .categories

      nuxt-link.category.category--all(
        :to="{name: 'products'}"
        :class="{active: current === undefined}"
        title="All")
        .icon.icon--all
        span.name.pf-ellipsis All

      nuxt-link.category.category--new(
        :to="$links.catalog({ query: { category_id: 0 } })"
        :class="{active: current === 0}"
        v-click="() => $amplitude.track('CLICK_HOME_CATEGORY_MENU', { category_id: 0, category_name: 'New' })"
        title="New")
        .icon.icon--new
        span.name.pf-ellipsis New

      template(v-for="i, k in records")
        el-dropdown(
          :key="k"
          :show-timeout="100"
          :hide-timeout="50"
          placement="bottom"
          v-if="i.product_types.length"
          @command="openSubCategory")
          el-dropdown-menu.category-dropdown(
            slot="dropdown"
            hide-on-click
            placement="bottom")
            el-dropdown-item(
              v-for="j, jk in i.product_types"
              :key="jk"
              :command="j") {{ j.name }}

          nuxt-link.category(
            :class="{active: i.id === current}"
            :key="k"
            :title="i.name"
            :to="$links.catalog({ query: { category_id: i.id } })"
            v-click="() => $amplitude.track('CLICK_HOME_CATEGORY_MENU', { category_id: i.id, category_name: i.name })")
            .icon(:class="{[getCategoryIcon(i)]: true}")
            span.name.pf-ellipsis(v-text="i.name")

        nuxt-link.category(
          v-else
          :class="{active: i.id === current}"
          :key="k"
          :title="i.name"
          :to="$links.catalog({ query: { category_id: i.id } })"
          v-click="() => $amplitude.track('CLICK_HOME_CATEGORY_MENU', { category_id: i.id, category_name: i.name })")
          .icon(:class="{[getCategoryIcon(i)]: true}")
          span.name.pf-ellipsis(v-text="i.name")
</template>

<script>
import { mapGetters } from 'vuex'
import { kebabCase } from 'lodash'

export default {
  computed: {
    ...mapGetters({
      records: 'master/productCategories'
    }),

    current () {
      const res = this.$route.query.category_id
      return (res !== undefined) ? parseInt(res) : undefined
    }
  },

  methods: {
    getCategoryIcon ({ name }) {
      return `icon--${kebabCase(name)}`
    },

    openSubCategory (cat) {
      if (!cat) return
      this.$amplitude.track('CLICK_HOME_CATEGORY_MENU', {
        category_id: cat.product_category_id,
        product_type_id: cat.id
      })
      const route = this.$links.catalog({
        query: {
          category_id: cat.product_category_id,
          'q[product_type_id]': cat.id
        }
      })
      this.$router
        .push(route)
        ?.catch(this.$debug.log)
    }
  }
}
</script>

<style lang="scss">
.category-dropdown {
  max-height: 50vh;
  overflow-y: scroll;
  margin-top: 1px !important;
  @include modern-scroller;
}
</style>

<style lang="scss" scoped>
.categories-bar {
  border-top: $--border-light;
  .scroller {
    overflow-x: auto;
    @include modern-scroller;
  }
  .categories {
    white-space: nowrap;
    text-align: center;
    .category {
      display: inline-block;
      width: 86px;
      font-size: 12.5px;
      color: $--color-text-regular;
      text-align: center;
      grid-gap: 0;
      padding: .65em 4px;
      background: white;
      &:hover {
        background: lighten($--color-primary, 55);
      }
      &.active {
        color: white;
        background: $--color-primary;
        .icon {
          background: white;
        }
      }
      .icon {
        margin: 0 auto;
        width: 28px;
        height: 28px;
        mask-image: url(/img/icon.categories@2x.webp);
        mask-repeat: no-repeat;
        mask-position: top left;
        mask-size: 280px;
        background: $--color-primary;
        @mixin cell($row, $col) {
          mask-position: top ($row * -28px) left ($col * -28px);
        }
        &--all { @include cell(0, 0); }
        &--baby-child { @include cell(0, 1); }
        &--bakery { @include cell(0, 2); }
        &--beverage { @include cell(0, 3); }
        &--cheese { @include cell(0, 4); }
        &--chill { @include cell(0, 5); }
        &--confections { @include cell(0, 6); }
        &--dairy { @include cell(0, 7); }
        &--fresh { @include cell(0, 8); }
        &--frozen { @include cell(0, 9); }
        &--grab-and-go { @include cell(1, 0); }
        &--grocery { @include cell(1, 1); }
        &--hemp { @include cell(1, 2); }
        &--household { @include cell(2, 3); }
        &--juice { @include cell(1, 4); }
        &--meat { @include cell(1, 5); }
        &--new { @include cell(1, 6); }
        &--prepared { @include cell(1, 7); }
        &--prepared-foods { @include cell(1, 8); }
        &--produce { @include cell(1, 9); }
        &--snacks { @include cell(2, 0); }
        &--wellness { @include cell(2, 1); }
        &--hba { @include cell(2, 1); }
        &--vms { @include cell(2, 2); }
      }
      .name {
        display: block;
        margin-top: 3px;
      }
    }
  }
}
</style>
